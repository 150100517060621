import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

class FileUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: "",
        };
    }

    handleClick = (e) => {
        this.inputElement.click();
    };

    onChangeHandler = (event) => {
        const [files] = event.target.files;
        if (files) {
            this.setState({ fileName: files.name});
            this.props.updateFile(files);
        }
    };

    render() {
        return (
            <div>
                <Button className="upload-file" onClick={this.handleClick} >
                    Upload a file
                </Button>
                <span> {this.state.fileName}</span>
                <input type="file"
                        ref={input => this.inputElement = input}
                        onChange={this.onChangeHandler}
                        style={{display:'none'}} 
                />
            </div>
        );
    }
}

FileUploader.propTypes = {
    updateFile: PropTypes.func.isRequired,
};

export default FileUploader;

