export default class MLModel {
    store = null;
    id = null;
    name = null;
    trainingDataFile = null;
    createdAt = null;
    updatedAt = null;

    constructor(store, json = null) {
        this.store = store;

        if (json) {
            this.id = json.id;
            this.updateFromJson(json);
        }
    }

    get asJson() {
        return {
            name: this.name,
            training_data_file: this.trainingDataFile,
            created_at: this.createdAt,
            update_at: this.updatedAt,
        };
    }

    updateFromJson(json) {
        this.name = json.name;
        this.createdAt = json.created_at;
        this.updatedAt = json.updated_at;
    }

    async delete() {
        if (!this.id) {
            return;
        }
        await this.store.apiService.deleteMLModel(this.id);
    }

    async save() {
        const json = this.asJson;
        if (this.id) {
            await this.store.apiService.updateMLModel(this.id, json);
        }
        else {
            const responseData = await this.store.apiService.createMLModel(json);
            this.id = responseData.id;
        }
    }

    async train() {
        if (!this.id || !this.name) {
            return;
        }
        const result = await this.store.apiService.trainMLModel(this.name);
        return result
    }
}
