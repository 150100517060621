export default class AppConfig {
    static get API_URL() {
        return `${this.ROOT_API_URL}/api/v3/`;
    }

    static get ROOT_API_URL() {
        return "https://api.dev.nrc.kujakuja.com";
    }

    static get ROOT_API_URL_ML() {
        return "https://api-hnk2b6eoca-wl.a.run.app/";
    }

    static get SITE_HEADER_DEFAULT_HIGHLIGHT_COLOUR() {
        return "#FFC300";
    }

    static get SITE_HEADER_DEFAULT_TITLE_TEXT() {
        return "Customer feedback";
    }

    static get MAPBOX_GL_ACCESS_TOKEN() {
        return "pk.eyJ1IjoiYmFycnlsYWNoYXBlbGxlIiwiYSI6IkVkT1FZX2MifQ.sSg105ALmN6eQgutNxWTOA";
    }

    static get MAPBOX_GL_MAP_STYLE_URL() {
        return "mapbox://styles/barrylachapelle/cjlwoyk4e3skr2smu4fl2xrjv";
    }

    static get FRONT_END_URL() {
        return "https://dev.nrc.kujakuja.com/en/";
    }

    static get GCP_PROJECT_ID() {
        return "kujakuja-nrc-dev";
    }
}
