import {
    flow, decorate, observable, computed,
} from "mobx";
import MLModel from "./MLModel";
import MLModelApiService from "./MLModelApiService";
import AppConfig from "../../AppConfig";

class MLModelStore {
    mlModels = [];
    isLoading = false;
    count = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.apiService = new MLModelApiService(AppConfig.API_URL, AppConfig.ROOT_API_URL_ML);
        this.loadMLModels();
    }

    get mlModelsSortedByName() {
        return this.mlModels.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            else if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }

    loadMLModels = flow(function* (config) {
        this.isLoading = true;
        try {
            const mlModelsJson = yield this.apiService.fetchAllMLModels(config);
            this.mlModels.replace(mlModelsJson.data.map((mlModelJson) => {
                return new MLModel(
                    this,
                    mlModelJson,
                );
            }));
            this.count = mlModelsJson.count;
            this.isLoading = false;
        }
        catch (error) {
            console.error(`MLModelStore failed to load ml models. ${error.message}`);
            this.isLoading = false;
        }
    });

    updateMLModelFromServer = flow(function* (id) { 
        this.isLoading = true;
        const mlModelsJson = yield this.apiService.fetchMLModelById(id);
        let mlModel = this.mlModels.find((st) => {
            return st.id === id;
        });

        if (mlModelsJson.length === 0 && mlModel) {
            yield this.removeMLModel(mlModel);
            mlModel = null;
        }
        if (mlModelsJson.length > 0) {
            if (mlModel) {
                mlModel.updateFromJson(mlModelsJson[0]);
            }
            else {
                mlModel = new MLModel(this, mlModelsJson[0]);
                this.mlModels.push(mlModel);
            }
        }
        this.isLoading = false;
        return mlModel;
    });

    removeMLModel = flow(function* (mlModel) { // eslint-disable-line func-names, require-yield
        this.mlModels.splice(this.mlModels.indexOf(mlModel), 1);
    });

    addMLModel = flow(function* (mlModel) { // eslint-disable-line func-names, require-yield
        this.mlModels.push(mlModel);
    });
}
decorate(MLModelStore, {
    mlModels: observable,
    mlModelsSortedByName: computed,
    isLoading: observable,
});

export default MLModelStore;
