import ApiService from "../ApiService";
import AppConfig from "../../AppConfig";

export default class MLModelApiService extends ApiService {
    fetchAllMLModels = async (mlModel) => {
        const mlModelsResponse = await this.makeHttpCall("ml_models/paginator", "post", mlModel);
        return mlModelsResponse.data; // TODO: change data structure returned by API
    };

    fetchMLModelById = async (id) => {
        const mlModelResponse = await this.makeHttpCall(`ml_models/${id}`);
        return mlModelResponse.data.data; // TODO: change data structure returned by API
    };

    deleteMLModel = async (id) => {
        const deleteMLModelResponse = await this.makeHttpCall(`ml_models/${id}`, "delete");
        // TODO: POST/PUT/DELETE endpoints should return string ID to be consistent with GET
        deleteMLModelResponse.data.id = deleteMLModelResponse.data.id.toString();
        return deleteMLModelResponse.data;
    };

    createMLModel = async (mlModelJson) => {
        console.log("createMLModel");
        console.log(mlModelJson);
        const data = new FormData();
        data.append("name", mlModelJson.name);
        data.append("training_data_file", mlModelJson.training_data_file);
        const mlModel = {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
            data: data,
        };
        const createMLModelResponse = await this.makeHttpCall("ml_models", "post", mlModel);

        createMLModelResponse.data.id = createMLModelResponse.data.id.toString();
        return createMLModelResponse.data;
    };

    updateMLModel = async (id, mlModelJson) => {
        console.log("updateMLModel");
        console.log(id);
        console.log(mlModelJson);


        const data = new FormData();
        data.append("name", mlModelJson.name);
        data.append("training_data_file", mlModelJson.training_data_file);
        const mlModel = {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
            data: data,
        };

        //const mlModel = { data: mlModelJson };
        const updateMLModelResponse = await this.makeHttpCall(`ml_models/${id}`, "put", mlModel);
        // TODO: POST/PUT/DELETE endpoints should return string ID to be consistent with GET
        updateMLModelResponse.data.id = updateMLModelResponse.data.id.toString();
        return updateMLModelResponse.data;
    };

    trainMLModel = async (modelName) => {
        const mlModel = {
            headers: {
                'Accept': "*/*",
            },
            params: {
                "projectId": AppConfig.GCP_PROJECT_ID,
                "bucketName": `${AppConfig.GCP_PROJECT_ID}-ml`,
                "modelName": modelName,
            }
        };

        const trainMLModelResponse = await this.makeHttpCall("trainModel", "POST", mlModel, true);
        console.log('trained')
        return trainMLModelResponse;
    };
}
