import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Alert, } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
    decorate, computed, observable, runInAction,
} from "mobx";
import { observer } from "mobx-react";
import { StoreContext } from "../../StoreContext";
import MLModel from "../../stores/MLModel/MLModel";

import Banner from "../Banner";
import BtnRow from "../BtnRow";
import ConfirmationModal from "../ConfirmationModal";
import FileUploader from "./FileUploader";
import Loading from "../Loading";

import "../Dashboard.css";
import "../../index.css";
import "../Edit.css";

class MLModelsEdit extends React.PureComponent {
    simpleState = {
        mlModel: null,
        isDeleteConfirmationModalOpen: false,
    }

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: "",
            successMsg: "",
            isLoading: false,
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.updateTrainingFile = this.updateTrainingFile.bind(this);
        this.cancelForm = this.cancelForm.bind(this);
    }

    async componentDidMount() {
        const { match, history } = this.props;
        const { mlModelStore } = this.context;

        if (match.params.id === "add") {
            runInAction(() => { 
                this.simpleState.mlModel = new MLModel(mlModelStore);
            });
        }
        else {
            const mlModel = await mlModelStore.updateMLModelFromServer(match.params.id);

            if (!mlModel) {
                history.push("/ml_models");
                return;
            }

            runInAction(() => {
                this.simpleState.mlModel = mlModel;
            });
        }
    }

    get elementAction() {
        const { match } = this.props;
        if (match.params.id === "add") {
            return "add";
        }
        return "update";
    }

    updateTrainingFile(file) {
        this.simpleState.mlModel.trainingDataFile = file;
        this.setState({ value: '' });
    }

    toggleDeleteConfirmationModal = () => {
        runInAction(() => {
            this.simpleState.isDeleteConfirmationModalOpen = !this.simpleState.isDeleteConfirmationModalOpen;
        });
    };

    delete = async () => {
        const { history } = this.props;
        await this.simpleState.mlModel.delete();
        history.push("/ml_models");
    };

    handleFormSubmit = async (event, values) => {
        try{
            const { history } = this.props;
            const mlModel = values;
            if(this.ValidateTrainingFile()){
                this.setState({ isLoading: true});
                runInAction(() => {
                    this.simpleState.mlModel.updateFromJson(mlModel);
                });

                await this.simpleState.mlModel.save();
                const result = await this.simpleState.mlModel.train();
                this.setState({
                    successMsg: result && result.data ?"Model has been trained successfully!" : "",
                    errorMsg: !result || !result.data ? "Error training the model - please try again" : "",
                    isLoading: false,
                });
            }
        }catch(error){
            this.setState({
                errorMsg: error.response ? "Error training the model - please try again" : error.message,
                successMsg: "",
                isLoading: false,
            });
        }
    };

    cancelForm = (e) => {
        const { history } = this.props;
        e.preventDefault();
        history.push("/ml_models");
    };
 
    ValidateTrainingFile = () => {
        var isValid = false;
        const _validFileExtensions = [".xlsx"];
        if(this.simpleState.mlModel && this.simpleState.mlModel.trainingDataFile && this.simpleState.mlModel.trainingDataFile.name) {
            var sFileName = this.simpleState.mlModel.trainingDataFile.name;
            if (sFileName.length > 0) {
                for (var j = 0; j < _validFileExtensions.length; j++) {
                    var sCurExtension = _validFileExtensions[j];
                    if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        isValid = true;
                        break;
                    }
                }         
            }
            if (!isValid) {
                this.setState({
                    errorMsg: "Sorry, " + sFileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "),
                    successMsg: "",
                    isLoading: false,
                });
                return false;
            }
        }

        return isValid;
    }

    render() {
        let buttonLabel;
        if (this.elementAction === "add") {
            buttonLabel = "Train";
        }
        else {
            buttonLabel = "Update";
        }
        const { errorMsg, successMsg, isLoading } = this.state;
        const { mlModel } = this.simpleState;
        return (
            <div className="page">

                <div className="title-background-parent">
                    <div className="title-background-shape" />
                </div>

                <Banner pageTitle="Train model" elementAction={this.elementAction} buttonFunction={this.toggleDeleteConfirmationModal} buttonDisabled={isLoading} buttonLabel="Delete Trained Model" />

                <ConfirmationModal
                    buttonFunction={this.delete}
                    toggle={this.toggleDeleteConfirmationModal}
                    isOpen={this.simpleState.isDeleteConfirmationModalOpen}
                />

                <Container>
                    <div className="white-page-bg">
                        <Row>
                            <Col lg={{ size: 10, offset: 1 }}>
                                <div>
                                    <AvForm encType="multipart/form-data" onValidSubmit={this.handleFormSubmit} model={mlModel}>
                                        <Row>
                                            <Col>
                                                <div>    
                                                <b>* This might take several minutes.</b>
                                                </div>
                                                <AvField
                                                    name="name"
                                                    label="Name"
                                                    required
                                                    readOnly={this.simpleState.mlModel && this.simpleState.mlModel.id > 0}
                                                    value={
                                                        this.simpleState.mlModel
                                                        && this.simpleState.mlModel.name
                                                    }
                                                />
                                                <FileUploader updateFile={this.updateTrainingFile} />
                                            </Col>
                                        </Row>
                                        {errorMsg[0]
                                        && (
                                            <Alert color="danger">
                                                {errorMsg}
                                            </Alert>
                                        )}
                                        {successMsg[0]
                                        && (
                                            <Alert color="success">
                                                {successMsg}
                                            </Alert>
                                        )}

                                        <BtnRow buttonLabel={buttonLabel} cancelForm={this.cancelForm} buttonDisabled={isLoading || (this.simpleState.mlModel && !this.simpleState.mlModel.trainingDataFile)} />
                                    </AvForm>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                { isLoading && <Loading /> }
            </div>
        );
    }
}

decorate(MLModelsEdit, {
    simpleState: observable,
    elementAction: computed,
});

MLModelsEdit.contextType = StoreContext;

MLModelsEdit.propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default observer(MLModelsEdit);
